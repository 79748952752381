import React from 'react';
import VideoBg from './src/components/HeroVideo';

export const wrapPageElement = ({ element, props, children, location }) => (
	<div>
		<>
			<VideoBg {...props} />
			{element}
		</>
	</div>
);
