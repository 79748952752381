import React from 'react';
import { Transition } from 'react-spring';

// import '../styles.css';
import { HeroVideo, VideoGradient } from './headings';

import fish_bgmp4 from '../video/the_fish1.mp4';
import fish_bgwebm from '../video/the_fish1.webm';

const VideoBg = ({ location }) => {
	const isHome = location.pathname === '/';
	return (
		<Transition
			items={isHome}
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0 }}
		>
			{isHome =>
				isHome &&
				(styles => (
					<div style={styles} key="herovideo">
						<VideoGradient>
							<HeroVideo>
								<source src={fish_bgmp4} type="video/mp4" />
								<source src={fish_bgwebm} type="video/webm" />
							</HeroVideo>
						</VideoGradient>
					</div>
				))
			}
		</Transition>
	);
};

export default VideoBg;
