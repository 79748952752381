import styled from 'styled-components';
import Link from 'gatsby-link';

import { media } from '../components/breakpoints';

const about = '#FFC647';
const projects = '#5220DD';
const contact = '#2980b9';

const MainTitle = styled.h1`
	margin-top: 40px;
	margin-bottom: 0;
	font-size: 64px;
	font-weight: 300;
	line-height: 1.2;
	color: darkslategrey;
	font-weight: 800;
	text-align: center;
	user-select: none;
	cursor: crosshair;
`;

const SiteHeader = styled.header`
	display: flex;
	justify-content: space-between;
	position: fixed;
	flex-wrap: wrap;
	top: 0;
	width: 100%;
	/* background: rgba(255, 255, 255, 0.5); */

	&:hover {
		h1 {
			transform: translate3d(80px, 0, 0);
			transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
		}
	}

	> a {
		/* color: blue; */
		color: ${props => props.color};
	}

	.tb-logo {
		height: 60px;
		width: auto;
		fill: ${props => props.logoColor};

		/* fill: #f1c40f; */
		/* color: #111; */
		padding: 10px 0 0 10px;
		z-index: 10;
		overflow: hidden;
		display: block;
	}

	img {
		width: 100px;
	}
`;

const Name = styled.h1`
	margin: 0;
	top: 0;
	position: absolute;
	display: none;
	z-index: 5;
	transform: translate3d(-100%, 0, 0);
	text-transform: uppercase;
	font-weight: 900;
	letter-spacing: 5px;
	font-size: 24px;
	text-decoration: none;
	font-weight: 900;
	line-height: 52px;
	color: white;
	transition: 0.3s all cubic-bezier(0.895, 0.03, 0.685, 0.22);
	${media.tablet`display: block;`};
`;

const SiteNav = styled.nav`
	> ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		> li {
			margin: 0;
		}
	}
`;

const NavLink = styled(Link)`
	text-decoration: none;
	color: #fff;
	font-size: 14px;
	padding: 15px 10px 18px;
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 5px;
	display: block;
	transition: all 0.3s ease;
	&:hover {
		border-bottom: 4px solid ${props => props.underline};
	}
`;

const HeroVideo = styled.video.attrs({
	preload: 'auto',
	autoPlay: true,
	loop: true,
	muted: true,
})`
	opacity: 1;
	position: fixed;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	left: 50%;
	transform: translateX(-50%);
	mix-blend-mode: overlay;
	/* mix-blend-mode: color-burn; */
`;

const VideoGradient = styled.figure`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	/* background: #00c3ff;
  background: -webkit-linear-gradient(to left, #00c3ff, #ffff1c);
  background: linear-gradient(to left, #00c3ff, #ffff1c); */

	/* background: #de6161;
background: -webkit-linear-gradient(to right, #2657eb, #de6161);
background: linear-gradient(to right, #2657eb, #de6161); */

	/* background-color: #92fe9d;
	background-image: -webkit-gradient(
		radial,
		100% 100%,
		0,
		100% 100%,
		200,
		from(rgb(0, 201, 255)),
		to(rgb(146, 254, 157))
	);
	background-image: -webkit-radial-gradient(
		100% 100%,
		rgb(146, 254, 157),
		rgb(0, 201, 255)
	);
	background-image: -moz-radial-gradient(
		100% 100%,
		rgb(146, 254, 157),
		rgb(0, 201, 255)
	);
	background-image: radial-gradient(
		100% 100%,
		rgb(146, 254, 157),
		rgb(0, 201, 255)
	); */

	background-color: #00c9ff;
	background-image: -webkit-gradient(
		linear,
		left top,
		right bottom,
		from(rgb(0, 201, 255)),
		to(rgb(146, 254, 157))
	);
	background-image: -webkit-linear-gradient(
		left top,
		rgb(0, 201, 255),
		rgb(146, 254, 157)
	);
	background-image: -moz-linear-gradient(
		left top,
		rgb(0, 201, 255),
		rgb(146, 254, 157)
	);
	background-image: linear-gradient(
		left top,
		rgb(0, 201, 255),
		rgb(146, 254, 157)
	);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#00c9ff', EndColorStr='#92fe9d');

	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -101;
`;

const Main = styled.main`
	max-width: 960px;
	margin: 60px auto;
	min-height: 70vh;
	padding: 100px 5% 80px;
`;

const MainWrapper = styled.div`
	a {
		color: ${props => props.color};
	}
`;

const Logo = styled.span`
	text-transform: uppercase;
	font-weight: 900;
	letter-spacing: 1px;
	background: #f1c40f;
	color: #111;
	padding: 10px;
	position: relative;
	z-index: 10;
	overflow: hidden;
	font-size: 26px;
	height: 52px;
	width: 52px;
	display: block;

	/* &:before {
		content: '';
		top: 0;
		left: 0;
		height: 52px;
		width: 156px;
		position: absolute;
		display: block;
		z-index: -1;
		transform: translate3d(
			${props => {
		if (props.page === 'about') return '0px';
		if (props.page === 'projects') return '-52px';
		if (props.page === 'contact') return '-104px';
		return '52px';
	}},
			0,
			0
		);
		background-image: linear-gradient(
			90deg,
			${about} 33.333%,
			${projects} 33.333%,
			${projects} 66.667%,
			${contact} 66.667%
		);
		background-size: 156px 52px;
		transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
	} */
`;

const Title = styled.h1`
	font-size: 38px;
	margin-bottom: 30px;
	text-transform: uppercase;
	font-weight: 900;
	letter-spacing: 5px;
	text-align: center;
`;

const SubHeader = styled.h3`
	text-transform: uppercase;
	font-weight: 900;
	margin-bottom: 40px;
	letter-spacing: 5px;
	text-align: center;
	font-size: 1.17em;
`;

export {
	SiteHeader,
	MainTitle,
	NavLink,
	HeroVideo,
	SiteNav,
	MainWrapper,
	VideoGradient,
	Main,
	Logo,
	Name,
	Title,
	SubHeader,
};
